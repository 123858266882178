import React from "react"
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import ServicesContent from "../components/servicesContent/index";

function Services({location, lang}) {
  return(
    <div>
      <Helmet bodyAttributes={{ class: 'whiteHeader whiteHeader-show' }}>
        <title>Voa Labs | Services</title>
        <meta name="description" content="Voa Labs strategizes, designs & builds digital experiences. We are a digital product studio from Switzerland." />
      </Helmet>
      <ServicesContent lang={lang} location={location} />
    </div>
  )
}

Services.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default Services;