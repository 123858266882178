import React from "react"
import Layout from "../layout"
import parse from 'html-react-parser';

import ServiceCard from '../ServiceCard/index'
import ProductCard from '../ProductCard/index'
import ServiceDetails from '../ServiceDetails/index'

import "./../layout.scss"
import './styles.scss'

function ServicesContent({ lang, location }) {

    const dictionaryList = {
        en: {
            title: 'We take an idea from a napkin sketch to a real shipped product.'
        },
        de: {
        }
    };

    let content;
    lang === "de" ? (content = dictionaryList.de) : (content = dictionaryList.en)

    return (
        <div>
            <Layout lang={lang} showLanguageSelector={false} location={location}>
                <section className="section content-section">
                    <div className="container">
                        <div className="columns">
                            <div class="column is-12">
                                <div class="column" className="">
                                    <h1 className="t-bold">{parse(content.title)}</h1>
                                    <div className="hero__subtitle">
                                        Have a great product idea but nobody to build it? We’re your team.
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section content-section is-dark">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-5">
                                <h2 className="sectionHeader">What we do</h2>
                                <div className="sectionSubheader">
                                    Strategize, design, and build digital experiences
                            </div>
                            </div>
                            <div className="column is-7">
                                <p>
                                    From discovery workshops, product strategy and design, all the way through to the development. We’re here to help you achieve your goals.
                                <br />
                                    <br />
                                Our network of bright entrepreneurial minds, technical wizards, creatives, and project management experts allow us create category-defining products – developing simple solutions for complex problems.
                            </p>
                            </div>
                        </div>
                        <div className="container ServiceCard__wrapper">
                            <div className="columns">
                                <div className="column is-6">
                                    <ServiceCard icon="/img/discovery-icon.png" headline="Discovery" description="We work together to understand your business objectives, aligning thoughts and ideas." anchor="discovery" />
                                </div>
                                <div className="column is-6">
                                    <ServiceCard icon="/img/product-strategy-icon.png" headline="Product Strategy" description="Build products for tomorrow. We help you determine where to go and define the optimum way to get there." anchor="product-strategy" />
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column is-6">
                                    <ServiceCard icon="/img/product-design-icon.png" headline="Product Design" description="Intuitive, seamless, and beautiful interfaces.<br />Always with a human-centered approach." anchor="product-design" />
                                </div>
                                <div className="column is-6">
                                    <ServiceCard icon="/img/engineering-icon.png" headline="Engineering" description="We craft clean, secure, stable, and extensible code. The best technologies, frameworks and libraries are used to build progressive applications." anchor="development" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section content-section">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-12">
                                <h2 className="sectionHeader">What we build</h2>
                                <div className="sectionSubheader">
                                    We build digital products, services, and experiences.
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="container ProductCard__wrapper">
                        <div className="columns">
                            <div className="column is-6">
                                <ProductCard backgroundColor="FF636F" headline="Mobile Apps" description="Native mobile apps for a society moving <br />increasingly towards mobile." image="/img/mobileapp.png" />
                            </div>
                            <div className="column is-6">
                                <ProductCard backgroundColor="5F5C5C" headline="Web Applications" description="Frontends that users enjoy using. <br /> High-performance backends that seamlessly integrate with your business processes." image="/img/webapplication.png" />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <ProductCard backgroundColor="020202" headline="Blockchain Applications" description="Transparent and secure. <br />Innovative applications beyond cryptocurrencies – driving positive changes across businesses and communities." image="/img/blockchainapp.png" widthPercentage="75" />
                            </div>
                            <div className="column is-6">
                                <ProductCard backgroundColor="ab8787" headline="Websites" image="/img/website.png" description="Sites that delight users and drive conversions. <br />Excellent designs and superior technical implementation." />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section content-section is-dark">
                    <ServiceDetails textPosition="left" header="Discovery" subheader="We work together to uncover the questions that lead to executing great ideas with even better outcomes." description="Before we talk about the technologies we need or what fonts the designer should use, we start by asking the right questions to align thoughts and ideas.<br /><br />Why are we doing this project? What are our long-term goals? What has to be tested first?" icon="/img/discovery-icon.png" image="/img/discovery.jpg" id="discovery" />
                    <ServiceDetails textPosition="right" header="Product Strategy" subheader="Defy outmoded business models and design products for tomorrow. We help you determine where to go and define the optimum way to get there." description="A solid product strategy is the foundation for a successful initiative. We find out how the product supports your business model and analyze the competition." icon="/img/discovery-icon.png" image="/img/product-strategy.jpg" id="product-strategy" />
                    <ServiceDetails textPosition="left" header="Product Design" subheader="Intuitive, seamless, and beautiful interfaces. Always with a human-centered approach." description="We create delightful user experiences, starting with UX research and wireframes, moving on to interactive prototypes and design systems." icon="/img/discovery-icon.png" image="/img/product-design.jpg" id="product-design" />
                    <ServiceDetails textPosition="right" header="Engineering" subheader="We craft clean, secure, stable, and extensible code. The best technologies, frameworks, and libraries are used to build progressive applications." description="Unsure of which programming language or infrastructure to use? We’re here to help – we’ll take care of it. <br ><br > React, React Native, HTML5/CSS3, Node.js, Python, or Solidity – whatever the language or framework ideal for the job, we’ll build the team with the necessary capabilities." icon="/img/discovery-icon.png" image="/img/development.jpg" id="development" />
                </section>
            </Layout>
        </div>
    )
}

export default ServicesContent;