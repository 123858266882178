
import React from 'react'

import './styles.scss'

const ServiceDetails = ({ textPosition, header, subheader, description, image, icon, id }) => {
    return (
        <div className="container ServiceDetails__container" id={ id }>
        <div className={`columns ${textPosition==='left' ? "is-reversed" : ""}`} >
        <div className="column is-6">
            <div className="ServiceImage" style={{backgroundImage: "url(" + image + ")"}}></div>  
        </div>
        <div className={`column is-6 ${textPosition === 'left' ? "paddingRight" : "paddingLeft"}`}>
            <div className="ServiceDetails__textWrapper">
                
                <h2 className="sectionHeader">{header}</h2>
                <div className="sectionSubheader">
                    { subheader }
                </div> 
                <br />
                <p className="ServiceDetails__text"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                </div>
            </div>
        </div>
       </div>
    )
}

export default ServiceDetails;
