import React from 'react';

import './styles.scss'

const ServiceCard = ({ headline, description, backgroundColor, image, widthPercentage }) => {

    return(
        <div className="ProductCard" style={{ backgroundColor: "#"+backgroundColor }}>
            <div className="Card__content">
                <div className="ProductCard__title">
                    { headline }
                </div>  
                <div>
                    <p className="ProductCard__text" dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                <div className="ProductCard__imageWrapper">
                    <img style={{ width: widthPercentage+"%" }} src={image} alt={`${headline}`}/>
                </div>
            </div>
        </div>
    )

    }

export default ServiceCard;