import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import './styles.scss'

const ServiceCard = ({ icon, headline, description, anchor, link }) => {

    return (
        <AnchorLink href={"#" + anchor}>
            <div className="Card">
                <div className="Card__content">
                    <div className="Card__icon">
                        <img src={icon} alt={`${headline} icon`}/>
                    </div>
                    <div className="Card__title">
                        {headline}
                    </div>
                    <div className="Card__text">
                        <p dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                    <div className="Card__link">
                        Learn more <img src="/img/arrow-right-blue-bold.svg" height="14" width="9"  alt="arrow icon"/>
                    </div>
                </div>
            </div>
        </AnchorLink>
    )

}

export default ServiceCard;